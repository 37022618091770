import { useEffect } from 'react';

import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Montserrat } from 'next/font/google';
import Head from 'next/head';

import { defaultTheme } from '@hultafors/wibe/helpers';
import { PageProps } from '@hultafors/wibe/types';
import '@hultafors/shared/style/main.scss';
import './global.scss';

export { reportWebVitals } from '@hultafors/shared/helpers';

const AppProvider = dynamic(() =>
  import('@hultafors/wibe/context').then((module) => module.AppProvider),
);

const Footer = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Footer),
);

const MarketSelectorQR = dynamic(() =>
  import('@hultafors/wibe/components').then(
    (module) => module.MarketSelectorQR,
  ),
);

const Nav = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Nav),
);

const TimedDialog = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.TimedDialog),
);

const SharedThemeProvider = dynamic(() =>
  import('@hultafors/shared/context').then((mod) => mod.SharedThemeProvider),
);

const fontMontserrat = Montserrat({
  subsets: ['latin'],
  variable: '--font-montserrat',
  weight: ['100', '300', '400', '500', '800'],
});

// TODO: Set types for page props
function CustomApp({ Component, pageProps, router }: AppProps<PageProps>) {
  const {
    global,
    menu,
    allMenuItems,
    maintenanceMode,
    timedDialog,
    footer,
    languageSelector,
    settings,
  } = pageProps;

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-family: ${fontMontserrat.style.fontFamily};
          }
          html,
          input,
          textarea,
          button,
          select {
            font-family: ${fontMontserrat.style.fontFamily};
          }
        `}
      </style>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <SharedThemeProvider theme={defaultTheme}>
        <AppProvider
          value={{
            allMenuItems,
            footer,
            global,
            languageSelector,
            maintenanceMode,
            menu,
            settings,
            timedDialog,
          }}
        >
          <Nav />
          <Component {...pageProps} />
          <GoogleTagManager gtmId="GTM-K7TXDV" />
          <Footer />
          <MarketSelectorQR />
          {timedDialog?.formUrl && (
            <TimedDialog timedDialogData={timedDialog} />
          )}
          <div id="portal"></div>
        </AppProvider>
      </SharedThemeProvider>
    </>
  );
}

export default CustomApp;
